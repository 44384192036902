<template>
  <div class="EduHome">
    <EduHeader />
    <main class="containerMain">
      <NavInfo />
      <div class="containerMainRight">
        <div class="outlineCenter">
          <div class="outlineCenter-Title">思考题</div>
          <div class="outlineCenter-Font">甲公司按单项存货、按年计提跌价准备。2012年1月1日“存货跌价准备”余额为190万元，其中B产品的存货跌价准备余额为10万元，C产品的存货跌价准备余额为180万元;对其他存货未计提存货跌价准备:2012年12月31日甲公司期末存货有关资料如下:</div>
          

          <div class="outlineCenteritem">
            <dl>
              <dd>
                <div class="outlineCenteritemTitle">
                  <div>借：应收账款 </div>
                  <div>3000</div>
                </div>
                <div class="outlineCenteritemInfo">
                  <div>贷：主营业务收入</div>
                  <div>3000</div>
                </div>
              </dd>
              <dd>
                <div class="outlineCenteritemTitle">
                  <div>借：应收账款 </div>
                  <div>3000</div>
                </div>
                <div class="outlineCenteritemInfo">
                  <div>贷：主营业务收入</div>
                  <div>3000</div>
                </div>
              </dd>
              <dd>
                <div class="outlineCenteritemTitle">
                  <div>借：应收账款 </div>
                  <div>3000</div>
                </div>
                <div class="outlineCenteritemInfo">
                  <div>贷：主营业务收入</div>
                  <div>3000</div>
                </div>
              </dd>
            </dl>
          </div>
          
          <ul class="tmItem">
            <li>
              <div class="outlineCenter-topic">第一题</div>
              <div class="outlineCenter-topicInfo">(1)A产品库存300台，单位成本为15万元，A产品市场销售价格为每台18万元，预计平均运杂费等销售税费为每台1万元,未签订不可撤销的销代合同。</div>
              <div class="outlineCenter-topicAsk">A产品期末资产负债表“存货”项目列报金额?</div>
              <div class="outlineCenter-answerTitle">答案</div>
              <div class="outlineCenter-answerInput">
                <input type="text" placeholder="请输入答案" />
              </div>
              <div class="outlineCenter-If">
                <i><img src="~@/assets/outline/naviconitem-2.png"></i>
                <span>正确！</span>
              </div>
              <div class="outlineCenter-explain">
                <div class="explainTitle">解析:</div>
                <div class="explainCenter">可变现净值=300× (18-1) =5100(万元)成本=300×15=4500(万元)则A产品不需要计提存货跌价准备。</div>
              </div>
            </li>
            <li>
              <div class="outlineCenter-topic">第二题</div>
              <div class="outlineCenter-topicInfo">B产品库存500台.单位成本为4.5万元，B产品市场销售价格为每台4.5万元。甲公司己经与长期客户某企业签订一份不可撤销的销售合同，约定在2013年2月10日向该企业销售B产品300台，合同价格为每台5万元。向长期客户销售的B产品平均运杂费等销售税费为每台0.3万元;向其他客户销售的B产品平均运杂费等销售税费为每台0.4万元。</div>
              <div class="outlineCenter-topicAsk">B产品期末资产负债表“存货”项目列报金额?</div>
              <div class="outlineCenter-answerTitle">答案</div>
              <div class="outlineCenter-answerInput">
                <input type="text" placeholder="请输入答案" />
              </div>
              <div class="outlineCenter-explain on">
                <div class="explainTitle">错误:</div>
                <div class="wrongReason">甲公司的会计处理不正确。</div>
                <div class="explainCenter">签订合同部分300台可变现净值=300× (5-0.3)=1410(万元)成本=300×4.5=1350(万元)则签订合同部分不需要计捉存货跌价准备。未签订合同部分200台可变现净值=200× (4.5-0.4)=820(万元)。成本=200×4.5=900(万元)应计提存货跌价准备=900- 820-10=70(万元)
                  <br><br>
                  借：资产减值损失  70
                  <br><br>
                  贷：存货跌价准备一B产品 70
                  <br><br>
                  答案等于1350+820=2170(万元)
                </div>
              </div>
            </li>
            <li>
              <div class="outlineCenter-topic">第三题</div>
              <div class="outlineCenter-topicAsk">以下哪种情况说明了历史成本原则？选择所有适用的。</div>
              <div class="outlineCenter-or">
                <dl>
                  <dd>
                    <div class="outlineCenter-orDdTitle">
                      <label>
                        <input checked name="tm4" type="radio">
                        <div>约翰想知道在他的自行车店里，哪种清点最合适的方法。他决定使用与过去几年相同的方法，因为他的业务基本上以同样的方式运营。</div>
                        <i><img src="~@/assets/outline/dui.png"></i>
                      </label>
                    </div>
                    <div class="outlineCenter-info">
                      这是一致性的例子，而不是历史成本。
                    </div>
                  </dd>
                  <dd>
                    <div class="outlineCenter-orDdTitle">
                      <label>
                        <input name="tm4" type="radio">
                        <div>约翰想知道在他的自行车店里，哪种清点最合适的方法。他决定使用与过去几年相同的方法，因为他的业务基本上以同样的方式运营。</div>
                        
                      </label>
                    </div>
                    <div class="outlineCenter-info">
                      这是一致性的例子，而不是历史成本。
                    </div>
                  </dd>
                  <dd>
                    <div class="outlineCenter-orDdTitle">
                      <label>
                        <input name="tm4" type="radio">
                        <div>约翰想知道在他的自行车店里，哪种清点最合适的方法。他决定使用与过去几年相同的方法，因为他的业务基本上以同样的方式运营。</div>
                        
                      </label>
                    </div>
                    <div class="outlineCenter-info">
                      这是一致性的例子，而不是历史成本。
                    </div>
                  </dd>
                  <dd>
                    <div class="outlineCenter-orDdTitle">
                      <label>
                        <input name="tm4" type="radio">
                        <div>约翰想知道在他的自行车店里，哪种清点最合适的方法。他决定使用与过去几年相同的方法，因为他的业务基本上以同样的方式运营。</div>
                        
                      </label>
                    </div>
                    <div class="outlineCenter-info">
                      这是一致性的例子，而不是历史成本。
                    </div>
                  </dd>
                </dl>
              </div>
              <div class="outlineCenter-If">
                <i><img src="~@/assets/outline/naviconitem-2.png"></i>
                <span>正确！</span>
              </div>
            </li>
            <li>
              <div class="outlineCenter-topic">第四题</div>
              <div class="outlineCenter-topicAsk">以下哪种情况说明了历史成本原则？选择所有适用的。</div>
              <div class="outlineCenter-or">
                <dl>
                  <dd>
                    <div class="outlineCenter-orDdTitle">
                      <label>
                        <input checked name="tm4" type="checkbox">
                        <div>约翰想知道在他的自行车店里，哪种清点最合适的方法。他决定使用与过去几年相同的方法，因为他的业务基本上以同样的方式运营。</div>
                        <i><img src="~@/assets/outline/cuo.png"></i>
                      </label>
                    </div>
                    <div class="outlineCenter-info">
                      这是一致性的例子，而不是历史成本。
                    </div>
                  </dd>
                  <dd>
                    <div class="outlineCenter-orDdTitle">
                      <label>
                        <input checked name="tm4" type="checkbox">
                        <div>约翰想知道在他的自行车店里，哪种清点最合适的方法。他决定使用与过去几年相同的方法，因为他的业务基本上以同样的方式运营。</div>
                        
                      </label>
                    </div>
                    <div class="outlineCenter-info">
                      这是一致性的例子，而不是历史成本。
                    </div>
                  </dd>
                  <dd>
                    <div class="outlineCenter-orDdTitle">
                      <label>
                        <input name="tm4" type="checkbox">
                        <div>约翰想知道在他的自行车店里，哪种清点最合适的方法。他决定使用与过去几年相同的方法，因为他的业务基本上以同样的方式运营。</div>
                        
                      </label>
                    </div>
                    <div class="outlineCenter-info">
                      这是一致性的例子，而不是历史成本。
                    </div>
                  </dd>
                  <dd>
                    <div class="outlineCenter-orDdTitle">
                      <label>
                        <input name="tm4" type="checkbox">
                        <div>约翰想知道在他的自行车店里，哪种清点最合适的方法。他决定使用与过去几年相同的方法，因为他的业务基本上以同样的方式运营。</div>
                        
                      </label>
                    </div>
                    <div class="outlineCenter-info">
                      这是一致性的例子，而不是历史成本。
                    </div>
                  </dd>
                </dl>
              </div>
              <div class="outlineCenter-explain on">
                <div class="explainTitle">错误:</div>
                <div class="explainCenter">
                  此题是第三章第二节内容，你需要再复习一次吗？如有需要请点击<a href="#">第三章第二节</a>进行学习
                </div>
              </div>
            </li>
            <li>
              <div class="outlineCenter-topic">题目</div>
              <div class="outlineCenter-topicInfo">判断甲公司的会计处理是否正确，并说明理由，对于甲公司不正确的会计处理，请编制更正2013年度财务报表相关项目的会计分录。</div>
              <div class="outlineCenter-topicAsk">A产品期末资产负债表“存货”项目列报金额?</div>
              <div class="outlineCenter-answerTitle">答案</div>
              <div class="outlineCenter-button"></div>
              <div class="outlineCenter-answerSelect">
                <div class="answerSelect" @click="showFn">{{info}}</div>
                <div class="answerSelectInfo" :class="{'on' : show}">
                  <dl>
                    <dd @click="showBu('对')">对</dd>
                    <dd @click="showBu('错')">错</dd>
                  </dl>
                </div>
              </div>
              <div class="outlineCenter-answerTitle">理由</div>
              <div class="outlineCenter-text">
                <textarea></textarea>
              </div>
              <div class="outlineCenter-answerTitle">更正</div>
              <div class="outlineCenter-item">
                <dl>
                  <dd>
                    <div class="showinfo">
                      <div class="outlineCenter-itemTitle">[1]</div>
                      <div class="outlineCenter-itemInfo">
                        <div class="w50">
                          <div class="centerItem">
                            <div class="centerItemTitle">借名称</div>
                            <div class="centerItemInput">
                              <input type="text" placeholder="应收账款" />
                            </div>
                          </div>
                        </div>
                        <div class="w50">
                          <div class="centerItem">
                            <div class="centerItemTitle">借名称</div>
                            <div class="centerItemInput">
                              <input type="text" placeholder="应收账款" />
                            </div>
                          </div>
                        </div>
                        <div class="w50">
                          <div class="centerItem">
                            <div class="centerItemTitle">借名称</div>
                            <div class="centerItemInput">
                              <input type="text" placeholder="应收账款" />
                            </div>
                          </div>
                        </div>
                        <div class="w50">
                          <div class="centerItem">
                            <div class="centerItemTitle">借名称</div>
                            <div class="centerItemInput">
                              <input type="text" placeholder="应收账款" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </dd>
                  <dd>
                    <div class="showinfo">
                      <div class="outlineCenter-itemTitle">[1]</div>
                      <div class="outlineCenter-itemInfo">
                        <div class="w50">
                          <div class="centerItem">
                            <div class="centerItemTitle">借名称</div>
                            <div class="centerItemInput">
                              <input type="text" placeholder="应收账款" />
                            </div>
                          </div>
                        </div>
                        <div class="w50">
                          <div class="centerItem">
                            <div class="centerItemTitle">借名称</div>
                            <div class="centerItemInput">
                              <input type="text" placeholder="应收账款" />
                            </div>
                          </div>
                        </div>
                        <div class="w50">
                          <div class="centerItem">
                            <div class="centerItemTitle">借名称</div>
                            <div class="centerItemInput">
                              <input type="text" placeholder="应收账款" />
                            </div>
                          </div>
                        </div>
                        <div class="w50">
                          <div class="centerItem">
                            <div class="centerItemTitle">借名称</div>
                            <div class="centerItemInput">
                              <input type="text" placeholder="应收账款" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </dd>
                  <dd>
                    <div class="showinfo">
                      <div class="outlineCenter-itemTitle">[1]</div>
                      <div class="outlineCenter-itemInfo">
                        <div class="w50">
                          <div class="centerItem">
                            <div class="centerItemTitle">借名称</div>
                            <div class="centerItemInput">
                              <input type="text" placeholder="应收账款" />
                            </div>
                          </div>
                        </div>
                        <div class="w50">
                          <div class="centerItem">
                            <div class="centerItemTitle">借名称</div>
                            <div class="centerItemInput">
                              <input type="text" placeholder="应收账款" />
                            </div>
                          </div>
                        </div>
                        <div class="w50">
                          <div class="centerItem">
                            <div class="centerItemTitle">借名称</div>
                            <div class="centerItemInput">
                              <input type="text" placeholder="应收账款" />
                            </div>
                          </div>
                        </div>
                        <div class="w50">
                          <div class="centerItem">
                            <div class="centerItemTitle">借名称</div>
                            <div class="centerItemInput">
                              <input type="text" placeholder="应收账款" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </dd>
                </dl>
              </div>
            </li>
          </ul>
          <div class="outlineBut">
            <button class="but1" type="button">关闭</button>
            <button class="but2" type="button">提交</button>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import EduHeader from "@/components/EduHeader";
import NavInfo from "@/components/NavInfo";
export default {
  name: "EduHome",
  data() {
    return {
      activeName: "Teaching",
      show:false,
      info:"对"
    };
  },
  methods: {
    showBu(row){
      this.info = row;
      this.show = false;
    },
    showFn(){
      this.show=!this.show
    }
  },
  components: {
    EduHeader,
    NavInfo,
  },
};
</script>

<style lang="scss" scoped>
.containerMain {
  position: relative;
  .containerMainRight {
    padding-left: 410px;
    .outlineCenter {
      width: 700px;
      margin: 0px auto;
      padding: 75px 0 15px 0;
      .outlineCenter-topic{
        display: inline-block;
        height: 40px;
        background: #636363;
        border: 1px solid #707070;
        opacity: 1;
        border-radius: 20px;
        line-height: 40px;
        padding: 0 25px;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #FFFFFF;
        opacity: 1;
        margin-top: 25px;
      }
      .outlineCenter-topicInfo{
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #060606;
        margin-top: 20px;
      }
      .outlineCenter-topicAsk{
        margin-top: 15px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 24px;
        color: #060606;
        opacity: 1;
      }
      .outlineCenter-answerTitle{
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #575B67;
        opacity: 1;
        margin-top: 25px;
      }
      .outlineCenter-text{
        margin-top: 15px;
        textarea{
          height: 107px;
          background: #FFFFFF;
          border: 1px solid #707070;
          opacity: 1;
          border-radius: 6px;
          width: 100%;
          padding: 10px 14px;
          box-sizing: border-box;
          color: #666666;
          line-height: 24px;
        }
      }
      .outlineCenter-item{
        dl{
          margin: 0 -6px;
          dd{
            float: left;
            width: 50%;
            box-sizing: border-box;
            padding: 0 6px;
            .showinfo{
              background: #FFFFFF;
              border: 1px solid #707070;
              opacity: 1;
              border-radius: 6px;
              margin-top: 12px;
              overflow: hidden;
              .outlineCenter-itemTitle{
                height: 52px;
                background: #E6E6E6;
                opacity: 1;
                text-align: center;
                font-size: 16px;
                font-family: Microsoft YaHei;
                color: #060606;
                opacity: 1;
                border-bottom: 1px solid #707070;
                line-height: 52px;
              }
              .outlineCenter-itemInfo{
                padding: 6px;
                .w50{
                  width: 50%;
                  box-sizing: border-box;
                  padding: 6px;
                  float: left;
                  .centerItem{
                    background: #FFFFFF;
                    border: 1px solid #707070;
                    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
                    opacity: 1;
                    border-radius: 6px;
                    padding: 10px;
                    .centerItemTitle{
                      font-size: 16px;
                      font-family: Microsoft YaHei;
                      font-weight: 400;
                      line-height: 36px;
                      color: #060606;
                      opacity: 1;
                    }
                    .centerItemInput{
                      input{
                        height: 45px;
                        background: #FFFFFF;
                        border: 1px solid #707070;
                        opacity: 1;
                        border-radius: 6px;
                        box-sizing: border-box;
                        padding: 0 14px;
                        font-size: 16px;
                        color: #333333;
                        width: 100%;
                      }
                    }
                    &:hover{
                      background: #A1B0C9;
                      border: 1px solid #707070;
                      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
                      opacity: 1;
                      border-radius: 6px;
                      .centerItemTitle{
                        color:#ffffff;
                      }
                    }
                  }
                  
                }
              }
            }
            
          }
        }
      }
      .outlineCenter-button{
        height: 45px;
        background: #173D7C url("~@/assets/outline/add.png") no-repeat center;
        border: 1px solid #707070;
        opacity: 1;
        border-radius: 6px;
        text-align: center;
        background-size: 24px;
        margin-top: 10px;
        cursor: pointer;
      }
      .outlineCenter-answerSelect{
        z-index: 1;
        display: inline-block;
        margin-top: 10px;
        cursor: pointer;
        height: 45px;
        background: #FFFFFF url("~@/assets/outline/iconDown.png") no-repeat 50px;
        border: 1px solid #707070;
        opacity: 1;
        border-radius: 6px;
        padding: 0 14px;
        width: 80px;
        position: relative;
        .answerSelect{
          line-height: 43px;
          font-size: 16px;
        }
        .answerSelectInfo{
          border-radius: 6px;
          position: absolute;
          left: -1px;
          width:80px;
          top: 43px;
          border: 1px solid #707070;
          opacity: 1;
          border-radius: 6px;
          display: none;
          background: #ffffff;
          &.on{
            display: block;
          }
          dl{
            dd{
              line-height: 36px;
              padding: 0 14px;
            }
          }
        }
      }
      .outlineCenter-answerInput{
        margin-top: 15px;
        input{
          height: 45px;
          background: #FFFFFF;
          border: 1px solid #707070;
          opacity: 1;
          border-radius: 6px;
          box-sizing: border-box;
          padding: 0 14px;
          font-size: 16px;
          color: #333333;
          width: 100%;
        }
      }
      .outlineCenter-If{
        height: 68px;
        background: #DFE9DF;
        border: 1px solid #307A35;
        opacity: 1;
        border-radius: 6px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #060606;
        opacity: 1;
        padding: 0 14px;
        margin-top: 20px;
        
        i{
          float: left;
          margin-top: 20px;
        }
        span{
          float: left;
          margin-left:18px;
          margin-top: 22px;
        }
      }
      .outlineCenter-explain{
        background: #FFFFFF;
        border: 1px solid #AEAEAE;
        opacity: 1;
        border-radius: 6px;
        padding: 15px 20px;
        margin-top: 20px;
        
        .explainTitle{
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #636363;
          opacity: 1;
          padding-left: 30px;
          background: url("~@/assets/outline/iconDown.png") no-repeat left;
          background-size: 16px 17px;
          line-height: 36px;
        }
        .explainCenter{
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #636363;
          opacity: 1;
          line-height: 24px;
          margin-top: 20px;
        }
        &.on{
          background: #EDE2E3;
          border: 1px solid #8D0005;
          opacity: 1;
          border-radius: 6px;
          .explainTitle{
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #060606;
            opacity: 1;
            background: url("~@/assets/outline/naviconitem-3.png") no-repeat left;
          }
          .wrongReason{
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #060606;
            opacity: 1;
            margin-top: 20px;
            padding-left: 30px;
          }
          .explainCenter{
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 24px;
            color: #060606;
            opacity: 1;
            padding-left: 30px;
            a:link,a:visited{
              color: #8D0005;
            }
          }
        }
      }
      .outlineCenter-or{
        margin-top: 10px;
        border: 1px solid #D6D6D6;
        opacity: 1;
        border-radius: 6px;
        dl{
          dd{
            .outlineCenter-orDdTitle{
              padding: 20px 14px;
              label{
                cursor: pointer;
                display: block;
                position: relative;
                input{
                  float: left;
                  margin-top: 6px;
                }
                div{
                  padding-left: 35px;
                  padding-right: 35px;
                  font-size: 16px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  line-height: 24px;
                  color: #060606;
                  opacity: 1;
                }
                i{
                  position: absolute;
                  right:0px;
                  top: 5px;
                }
              }
            }
            .outlineCenter-info{
              background: #E8E8E8;
              opacity: 1;
              border-radius: 0px;
              padding: 20px 20px 20px 50px;
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              line-height: 24px;
              color: #060606;
              opacity: 1;
            }
          }
        }
      }
      .outlineCenter-Title {
        font-size: 36px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 0px;
        color: #060606;
        opacity: 1;
        line-height: 50px;
      }
      .outlineCenter-video {
        margin-top: 15px;
        video {
          height: 450px;
          width: 100%;
          background: #000000;
        }
      }
      .outlineCenter-Title-1 {
        line-height: 40px;
        font-size: 28px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #575b67;
        opacity: 1;
        margin-top: 30px;
      }
      .outlineCenter-Font {
        margin-top: 15px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #060606;
      }
      .outlineCenteritem{
        dl{
          dd{
            padding: 10px 0;
            border-bottom: 1px solid #707070;
            .outlineCenteritemTitle{
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              line-height: 24px;
              color: #060606;
              opacity: 1;
              padding: 5px 0;
              &::after{
                clear: both;
                display: block;
                content: '';
              }
              div{
                float: left;
                width: 50%;
                box-sizing: border-box;
                padding-right: 30px;
              }
            }
            .outlineCenteritemInfo{
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              line-height: 24px;
              color: #060606;
              opacity: 1;
              padding: 5px 0;
              &::after{
                clear: both;
                display: block;
                content: '';
              }
              div{
                float: left;
                width: 50%;
                box-sizing: border-box;
                padding-right: 30px;
              }
            }
          }
        }
      }
      .outlineCenter-Ul {
        margin-top: 30px;
        li {
          position: relative;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 32px;
          color: #575a63;
          opacity: 1;
          padding-left: 15px;
          &::after {
            display: block;
            width: 5px;
            height: 5px;
            position: absolute;
            left: 0;
            top: 13px;
            content: "";
            background: #575a63;
            border-radius: 50%;
          }
        }
      }
      .page-but {
        margin-top: 30px;
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #575a63;
        opacity: 1;
      }
    }
  }
}
.outlineBut{
  padding: 50px 0;
  text-align: center;
  button{
    height: 44px;
    
    opacity: 1;
    border-radius: 8px;
    padding: 0 20px;
    margin: 0 10px;
    cursor: pointer;
    color: #ffffff;
    border: 0;
    &.but1{
      background: #5A5A5D;
    }
    &.but2{
      background: #173D7C;
    }
  }
}
.tmItem li::after{
  clear: both;
  display: block;
  content: '';
}
.EduHome {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  footer {
    padding: 24px 40px 40px;
    font-size: 16px;
    color: $color-text-6;
    background-color: $color-background-5;
  }
}
</style>
